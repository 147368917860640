import axios from 'axios';
import { Message } from 'element-ui';
// import store from '@/store'
import { getToken } from '@/utils/auth';
import router from '@/router';

let baseURL = process.env.VUE_APP_BASE_URL;
if (process.env.NODE_ENV === 'development') {
  baseURL = '/api';
}

const responseWhiteList = [
  'user-web-api/admin/user/info/platform/updateById',
  'user-web-api/admin/user/info/platform/add'
];

const service = axios.create({
  baseURL,
  timeout: 60000 // request timeout
});

service.interceptors.request.use(
  // eslint-disable-next-line arrow-body-style
  (config) => {
    let token = '';

    if (process.env.NODE_ENV === 'development') {
      token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgGIsw9n7ns7QseLueCGHP4yrQlbmdzKr8wCwaAmbu3Ul5IFG4wBdToBg7DSSNtUWt50tgfosEPOnuEaZCPa+P3Yt6paWxk767Tx+AoXQYJHu+FfmFgXcj4ni+JtA9PLbyT3glKtK+x4PO9aIzsraRktpmyN/ds8D1twFZXv9cWOigsDigt6jphsXNU2mutDf3TFta2/i/2MFgk/jKvbjvfuFpppw34y2w7YDrCAAZNn4H1E1irB7L0p74YSZBGgFzg==.7f5b5359cd22a776cc95e5f77642d391c374a3f5';
    } else {
      token = getToken();
    }

    if (token) {
      config.headers.accessToken = token;
    }

    //1-大片来了，2-JPG
    const bizLineId = Number(router.currentRoute.query.bizLineId);
    if (config.data && config.data.data && typeof config.data.data == 'object' && bizLineId) {
      config.data.data['bizLineId'] = bizLineId;
    }

    return config;
  },
  (error) => {
    console.log(error, 'request error');
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    const {
      config: { responseType, allResult, rawResponse }
    } = response;

    const res = response.data;

    if (res.code === '401') {
      Message({
        message: res.description,
        type: 'error',
        duration: 3 * 1000
      });
      window.location.href = res.redirectUrl;
      // eslint-disable-next-line consistent-return
      return;
    }
    if (res.success === false) {
      Message({
        message: res.description,
        type: 'error',
        duration: 3 * 1000
      });
    }

    if (responseType === 'blob') {
      // eslint-disable-next-line consistent-return
      return response.data;
    }

    if (allResult) {
      return res;
    }

    if (rawResponse) {
      return response;
    }

    // eslint-disable-next-line consistent-return
    return res.data;
  },
  (error) => {
    const respose = error.response;
    Message({
      message: respose.data.description,
      type: 'error',
      duration: 3 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
